import API from "../Network/API"
import toast from 'react-hot-toast';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useNavigate } from "react-router-dom";
export default function Login() {
  const { user, accessToken } = useSelector((state) => state.authReducer);
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [isLoading, setLoading] = useState(false);
  const [errors, setErrors] = useState({});
  const dispatch = useDispatch();
  const navigate=useNavigate();


  useEffect(() => {
    if (accessToken) {
      
      if (user?.role == "teacher") {
        window.location.href = '/dashboard/tutor';
     } else if (user?.role == "student") {
       window.location.href = '/home/student';
     } else {
       window.location.href = '/';
     }
    }

  }, []);


  const handleSubmit = (event) => {
    event.preventDefault();
    setLoading(true);
    if (typeof email !== "undefined") {
      if (!email.match(/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w\w+)+$/)) {
        toast.error('Email is not correct!');
        return false;
      }
    }
    var data = {
      'email': email,
      'password': password,
    }
    // headers: { 'content-type': 'application/x-www-form-urlencoded' }
    API.post('/v1/oauth/login', data).then(response => {
      setLoading(false);
      if (response.data.success === true) {

        localStorage.setItem(
          'xller_auth',
          JSON.stringify({
            user: response?.data?.data?.user,
            accessToken: response?.data?.data?.access_token,
          })
        )

        localStorage.setItem("xller_token", response?.data?.data?.access_token)
          // console.log(localStorage.getItem('data_checkout'))
        if(localStorage.getItem('data_checkout')!=null && localStorage.getItem('data_checkout')!=undefined){
          // navigate('/checkout')
          window.location.href = '/checkout';
        }

        if (response?.data?.data?.user?.role == "teacher") {
           window.location.href = '/dashboard/tutor';
        } else if (response?.data?.data?.user?.role == "student") {
          window.location.href = '/home/student';
        } else {
          window.location.href = '/';
        }

        toast.success(response.message, { duration: 3000 })

      } else {

      }
    }).catch((error) => {
      setLoading(false);
      if (error.response && error.response.data && error.response.data.data) {
        setErrors(error.response.data.data);
      }else if(error.response && error.response.data && !error.response.data.data){
         setErrors(error.response.data);
      } else {
        // console.log("failed");
      //  console.log(error.response?.data);
      }
    });

  }

  return (
    <>
      <div className="sign-in-section">
        <div className="container">
          <div className="main-max-width d-flex justify-content-center mxw-400px">
            <div className="sign-content">
              <div className="box-content mb-4">
                <h4>Log in</h4>
                <div className="singup-link ">
                  <span className>
                    <Link to={`/signup-student`} className="text-decoration-underline text-dark me-1">Sign up as a student</Link>
                  </span>
                  <span className="text-dark">or</span>
                  <span>
                    <Link to={`/signup-tutor`} className="text-decoration-underline text-dark ms-1">Sign up as a tutor</Link>
                  </span>
                </div>
              </div>
              <div className="acount-s mb-20 d-none">
                <a href="#" className="btn rounded-pill w-100 mb-2 input-hight-44">
                  <img src="assets/img/google.svg" alt="image" /> Sign in with Google
                </a>
                <a href="#" className="btn rounded-pill w-100 mb-2 input-hight-44">
                  <img src="assets/img/facebook-3-logo-svgrepo-com.svg" alt="image" /> Sign in with Facebook
                </a>
                <a href="#" className="btn rounded-pill w-100 mb-2 input-hight-44">
                  <img src="assets/img/apple-logo-svgrepo-com.svg" alt="image" /> Continue with Apple
                </a>
              </div>
              <div className="loginDivider d-none">
                <p className="loginDivider-text mb-0">or</p>
              </div>
              <div className="log-from mb-30">
                <form onSubmit={handleSubmit}>
                  <div className="form-group mb-2">
                    <label htmlFor="exampleInputEmail1" className="form-label ">Email</label>
                    <input required onChange={(e) => setEmail(e.target.value)} type="email" className="form-control rounded-pill input-hight-44" id="exampleInputEmail1" placeholder="Your Email" />
                  </div>
                  <div className="form-group mb-1">
                    <label htmlFor="exampleInputPassword1" className="form-label">Password</label>
                    <input required onChange={(e) => setPassword(e.target.value)} type="password" className="form-control input-hight-44 rounded-pill" id="exampleInputPassword1" placeholder="Your Password" />
                    {errors.message && <div className="text-danger">{errors.message}</div>}
                  </div>
                  <div className="meta-info d-flex justify-content-between mb-3">
                    <Link to={`/forgot-password`} className="text-dark text-decoration-underline">Forgot your password?</Link>
                  </div>
                  <button type="submit" className="input-hight-44 btn btn-login w-100 box-shadow-1 rounded-pill fw-bold">Login{isLoading ? (
                    <span
                      className="spinner-border spinner-border-sm ml-5"
                      role="status"
                      aria-hidden="true"
                    ></span>
                  ) : (
                    <span></span>
                  )}</button>
                </form>
              </div>
              <div className="sign-link text-center">
                <span>By clicking Log in or Continue with, you agree to Xller <Link to={`/terms`} className="main-colo">Terms of Use</Link> and <Link to={`/privacy-policy`} className="main-colo">Privacy Policy</Link></span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}