import { useEffect, useState } from "react";
import { Link, useParams } from "react-router-dom";
import API from "../../Network/API";
import { useSelector } from "react-redux";
import { JitsiMeeting } from "@jitsi/react-sdk";

export default function LessonView() {
  const [isLoading, setLoading] = useState(false);
  const [data, setData] = useState();
  const [lesson, setLesson] = useState();
  const prams = useParams();
  const { user, accessToken } = useSelector((state) => state.authReducer);

  useEffect(() => {
    setLoading(true)
    API.get(`/v1/oauth/room/${prams.room_id}`)
      .then((response) => {
        setData(response.data.data.course)
        setLoading(false)
      }).catch((error) => {
      });
  }, [prams.room_id]);

  
  const markComplete = () => {
    API.post(`/courses/${data?.id}/lessons/${prams.lessonId}/complete`)
      .then(response => {

      })
      .catch(error => console.error(error));
  };


  return (
    <>
      <div className="lessonPage">
        <div className="lessonCourseWrapper">
          <div className="chapter-list">
            <div className="bg-white flex-shrink-0">
              <div className="chapter-list-wrapper pt-3">
                <div className="px-4 chapter-header">
                  <div className="chapter-heading mb-2">
                    <h2 className="fs-24-600">Chapters</h2>
                  </div>
                  <div className="d-flex gap-2 pb-3 whitespace-nowrap text-dark">
                    <span className="uppercase"><i className="fa-solid fa-layer-group me-2" /> Lesson</span>
                    <span>|</span>
                    <span><i className="fa fa-clock me-2" /> Minutes</span>
                    <span>|</span>
                    <span><i className="fa-solid fa-flag-swallowtail me-2" /></span>
                  </div>
                  <div className="chapter-list-inner">
                    <div className="single-chapter-list-inner">
                      <a className="single-chapter-list-menu text-dark" data-bs-toggle="collapse" href="#chapter-12" role="button" aria-expanded="false" aria-controls="collapseExample">
                        <div className="single-chapter-list active">
                          <div className="single-chapter-list-padding">
                            <div className="d-flex justify-content-between align-items-center">
                              <div className=" flex-shrink-0 chapter-list-count"><span className="text-sm z-10 font-medium">0</span></div>
                              <div className="flex-grow-1 ps-3 pe-2 chapter-list-name"><span className="chapter-list-name-title ">Live Class-1</span></div>
                              <div className="chapter-list-time">00:00:00</div>
                            </div>
                          </div>
                        </div>
                      </a>
                      <div id="chapter-12" className="collapse single-chapter-list-content show">
                        <div className="all-sub-chapter">
                          <div className="d-flex align-items-center">
                            <div className="d-flex chapter-list-play-icon"><i className="fa-light fa-play text-danger" /></div>
                            <div className="chapter-list-dec">
                              <p className="mb-0 single-chapter-list-content-text fw-bold">{data?.lesson?.title}</p>
                              <p className="mb-0 single-chapter-list-content-text fw-bold" style={{fontSize:'10px'}}>{user.role=='student'?data?.student_local_start_time:data?.teacher_local_start_time}</p>
                              <div className="d-flex gap-3 items-center single-chapter-list-content-link"><a className="underline">Preview</a><a className="underline">{data?.duration}</a></div>
                            </div>
                          </div>
                          
                         
                        </div>
                      </div>
                  
                      
                    </div>
                  </div>
                </div>

              </div>
            </div>
          </div>
          <div className="lesson-course-content">
            <div className="lecture-container">
              <div className="lecture-header d-flex justify-content-between align-items-start mb-3">
                <div className="lecture-header-left">
                  <nav aria-label="breadcrumb chapter-breadcrumb">
                    <ol className="breadcrumb fs-16-400 mb-2 p-0 breadcrumb-list">
                      <li className="breadcrumb-item"><a href="#">Home</a></li>
                      <li className="breadcrumb-item"><a href="#">Library</a></li>
                      <li className="breadcrumb-item active" aria-current="page">Data</li>
                    </ol>
                  </nav>
                  <h3 className="lecture-title mb-0 fw-bold">{lesson?.title}</h3>
                </div>
                <Link to={`/home/student`} className="btn btn-dark"><i className="fa-light fa-angle-left me-1" />Back to Dashboard</Link>
              </div>
              <div className="lecture-content-inner">
                <div className="lecture-content-inner-video">
                  <div className="video-area">
                    {!isLoading?
                    <JitsiMeeting
                      domain='room.xller.com'
                      roomName={data?.room_no??'7899'}
                      configOverwrite={{
                        startWithAudioMuted: true,
                        disableModeratorIndicator: true,
                        startScreenSharing: true,
                        enableEmailInStats: false
                      }}
                      interfaceConfigOverwrite={{
                        DISABLE_JOIN_LEAVE_NOTIFICATIONS: true
                      }}
                      userInfo={{
                        displayName: user?.name??''
                      }}
                      onApiReady={(externalApi) => {
                        // custom event listeners to the Jitsi Meet External API
                      }}
                      getIFrameRef={(iframeRef) => { iframeRef.style.height = '400px'; }}
                    />
                    :''}


                  </div>
                </div>
                <div className="lecture-content-inner-dec ">
                  <div className="next-pre-btn my-3">
                    <div className="d-flex justify-content-between align-items-center">

                      {/* <Link to={``} className="pre-btn btn btn-dark btn-sm"> Complete <i className="fa-light fa-arrow-right ms-1" /></Link>
                     */}
                    </div>
                  </div>
                  <div className="mainInfoWrapperTab">
                    <div className="courseDetailsInfoWrapperTab">
                      <div className="dashboard__nav-wrap">
                        <ul className="nav nav-tabs" id="courseTab" role="tablist">
                          <li className="nav-item" role="presentation">
                            <button className="nav-link active" id="About" data-bs-toggle="tab" data-bs-target="#About-pane" type="button" role="tab" aria-controls="About-pane" aria-selected="true">
                              Script
                            </button>
                          </li>
                          <li className="nav-item" role="presentation">
                            <button className="nav-link" id="Schedule" data-bs-toggle="tab" data-bs-target="#Schedule-tab-pane" type="button" role="tab" aria-controls="Schedule-tab-pane" aria-selected="false" tabIndex={-1}>
                              comments
                            </button>
                          </li>

                        </ul>
                      </div>
                      <div className="tab-content" id="courseTabContent">
                        <div className="tab-pane fade active show" id="About-pane" role="tabpanel" aria-labelledby="About" tabIndex={0}>
                          <div className="courseDetailsTabHeading">
                            <h2 className="fs-24-600 mb-0">About the Chapter</h2>
                          </div>
                          <div className="courseDetailsTabDec">
                            <p className="fs-16-400">{lesson?.description}</p>

                          </div>
                        </div>
                        <div className="tab-pane fade" id="Schedule-tab-pane" role="tabpanel" aria-labelledby="Schedule-tab" tabIndex={0}>
                          <div className="courseDetailsTabReviews">
                            <div className="courseReviews">
                              <div className="d-flex">
                                <div className="flex-shrink-0">
                                  <div className="ratings-view">
                                    <h1 className="mb-2">{data?.avg_rating}</h1>
                                    <p className="mb-2 icon-20 text-warning">
                                      <i className={`fas fa-star ${data?.avg_rating >= 0 && data?.avg_rating <= 5 ? 'text-warning' : 'text-dark'}`} />
                                      <i className={`fas fa-star ${data?.avg_rating >= 2 && data?.avg_rating <= 5 ? 'text-warning' : 'text-dark'}`} />
                                      <i className={`fas fa-star ${data?.avg_rating >= 3 && data?.avg_rating <= 5 ? 'text-warning' : 'text-dark'}`} />
                                      <i className={`fas fa-star ${data?.avg_rating >= 4 && data?.avg_rating <= 5 ? 'text-warning' : 'text-dark'}`} />
                                      <i className={`fas fa-star ${data?.avg_rating >= 5 && data?.avg_rating <= 5 ? 'text-warning' : 'text-dark'}`} />

                                    </p>
                                    <p className="mb-0">{data?.ratings_count} reviews</p>
                                  </div>
                                </div>

                              </div>
                            </div>
                            <div className="courseRating">

                              <div className="courseRatingSorting">
                                {data?.ratings?.length > 0 && data?.ratings?.map((review, j) => (
                                  <div className="signalRating mb-3">
                                    <div className="card">
                                      <div className="card-body">
                                        <div className="d-flex">
                                          <div className="flex-shrink-0">
                                            <div className="userAvater">
                                              <img src={review?.student?.avatar ? review?.student?.avatar : "/assets/img/user-1.png"} alt={review?.student?.name} className="rounded" />
                                            </div>
                                          </div>
                                          <div className="flex-grow-1 ms-3">
                                            <div className="signalRatingHeader d-flex mb-1">
                                              <h5 className="m-0 fs-16-400 fw-bold">{review?.student?.name}</h5>
                                              <span className="ms-auto">{review?.created_at}</span>
                                            </div>
                                            <div className="rating mb-3">
                                              <span className="reviewsRatingColor">
                                                <i className={`fas fa-star ${review?.rating >= 0 && review?.rating <= 5 ? 'text-warning' : 'text-dark'}`} />
                                                <i className={`fas fa-star ${review?.rating >= 2 && review?.rating <= 5 ? 'text-warning' : 'text-dark'}`} />
                                                <i className={`fas fa-star ${review?.rating >= 3 && review?.rating <= 5 ? 'text-warning' : 'text-dark'}`} />
                                                <i className={`fas fa-star ${review?.rating >= 4 && review?.rating <= 5 ? 'text-warning' : 'text-dark'}`} />
                                                <i className={`fas fa-star ${review?.rating >= 5 && review?.rating <= 5 ? 'text-warning' : 'text-dark'}`} />

                                              </span>
                                            </div>
                                          </div>
                                        </div>
                                        <p className="m-0">{review?.comment}</p>
                                      </div>
                                    </div>
                                  </div>
                                ))}
                                {/* <a href="#" className="btn btn-link text-dark d-flex justify-content-end">See more reviews</a> */}
                              </div>
                            </div>
                            <div className="user-comments">
                              <div className="signalRating mb-3">
                                <div className="card">
                                  <div className="card-body">
                                    <div className="d-flex">
                                      <div className="flex-shrink-0">
                                        <div className="userAvater">
                                          <img src={user?.avatar} alt={user?.name} className="rounded" />
                                        </div>
                                      </div>
                                      <div className="flex-grow-1 ms-3">
                                        <div className="signalRatingHeader d-flex mb-1">
                                          <h5 className="m-0 fs-16-400 fw-bold">{user?.name}</h5>
                                        </div>
                                        <div className="rating mb-3">
                                          <span className="reviewsRatingColor">
                                            <i className="fa-solid fa-star text-warning" />
                                            <i className="fa-solid fa-star text-warning" />
                                            <i className="fa-solid fa-star text-warning" />
                                            <i className="fa-solid fa-star text-warning" />
                                            <i className="fa-solid fa-star text-warning" />
                                          </span>
                                        </div>
                                      </div>
                                    </div>
                                    <div data-mdb-input-init className="form-outline">
                                      <textarea className="form-control" id="textAreaExample" rows={4} defaultValue={""} />
                                      <label className="form-label" htmlFor="textAreaExample">What is your view?</label>
                                    </div>
                                    <div className="mt-2 d-flex justify-content-end">
                                      <button type="button" data-mdb-button-init data-mdb-ripple-init className="btn btn-dark">
                                        Send <i className="fa-light fa-send ms-1" />
                                      </button>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>

                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="mobile-show mobile-chapter-list">
          <div className="chapter-list-show-btn">
            <a className="btn btn-dark fw-bold" data-bs-toggle="offcanvas" href="#chapterList" role="button" aria-controls="offcanvasExample">
              View Chapter List <i className="fa-light fa-layers ms-1" />
            </a>
          </div>
          <div className="offcanvas offcanvas-start" tabIndex={-1} id="chapterList" aria-labelledby="offcanvasExampleLabel">
            <div className="offcanvas-header">
              <button type="button" className="btn-close" data-bs-dismiss="offcanvas" aria-label="Close" />
            </div>
            <div className="offcanvas-body p-0">
              <div className="chapter-list-wrapper pt-3">
                <div className="px-4 chapter-header">
                  <div className="chapter-heading mb-2">
                    <h2 className="fs-24-600">Chapters</h2>
                  </div>
                  <div className="d-flex gap-2 pb-3 whitespace-nowrap text-dark">
                    <span className="uppercase"><i className="fa-solid fa-layer-group me-2" />20 Chapters</span>
                    <span>|</span>
                    <span><i className="fa fa-clock me-2" /> 1:53:05</span>
                    <span>|</span>
                    <span><i className="fa-solid fa-flag-swallowtail me-2" />0%</span>
                  </div>
                </div>
                <div className="chapter-list-inner">
                  <div className="single-chapter-list-inner">
                    <a className="single-chapter-list-menu text-dark" data-bs-toggle="collapse" href="#chapter-1" role="button" aria-expanded="false" aria-controls="collapseExample">
                      <div className="single-chapter-list active">
                        <div className="single-chapter-list-padding">
                          <div className="d-flex justify-content-between align-items-center">
                            <div className=" flex-shrink-0 chapter-list-count">
                              <span className="text-sm z-10 font-medium">01</span>
                            </div>
                            <div className="flex-grow-1 ps-3 pe-2 chapter-list-name">
                              <span className="chapter-list-name-title ">Setting up our Symfony App</span>
                            </div>
                            <div className="chapter-list-time">4:34</div>
                          </div>
                        </div>
                      </div>
                    </a>
                    <div id="chapter-1" className="collapse single-chapter-list-content">
                      <div className="all-sub-chapter">
                        <div className="d-flex align-items-center">
                          <div className="d-flex chapter-list-play-icon">
                            <i className="fa-light fa-play" />
                          </div>
                          <div className="chapter-list-dec">
                            <p className="mb-0 single-chapter-list-content-text">Introduction to the User</p>
                            <div className="d-flex gap-3 items-center single-chapter-list-content-link">
                              <a href="#" className="underline">Preview</a>
                              <a href="#" className="underline">03:56</a>
                            </div>
                          </div>
                        </div>
                        <div className="d-flex align-items-center">
                          <div className="d-flex chapter-list-play-icon">
                            <i className="fa-light fa-play" />
                          </div>
                          <div className="chapter-list-dec">
                            <p className="mb-0 single-chapter-list-content-text">Introduction to the User</p>
                            <div className="d-flex gap-3 items-center single-chapter-list-content-link">
                              <a href="#" className="underline">Preview</a>
                              <a href="#" className="underline">03:56</a>
                            </div>
                          </div>
                        </div>
                        <div className="d-flex align-items-center">
                          <div className="d-flex chapter-list-play-icon">
                            <i className="fa-light fa-play" />
                          </div>
                          <div className="chapter-list-dec">
                            <p className="mb-0 single-chapter-list-content-text">Introduction to the User</p>
                            <div className="d-flex gap-3 items-center single-chapter-list-content-link">
                              <a href="#" className="underline">Preview</a>
                              <a href="#" className="underline">03:56</a>
                            </div>
                          </div>
                        </div>
                        <div className="d-flex align-items-center">
                          <div className="d-flex chapter-list-play-icon">
                            <i className="fa-light fa-play" />
                          </div>
                          <div className="chapter-list-dec">
                            <p className="mb-0 single-chapter-list-content-text">Introduction to the User</p>
                            <div className="d-flex gap-3 items-center single-chapter-list-content-link">
                              <a href="#" className="underline">Preview</a>
                              <a href="#" className="underline">03:56</a>
                            </div>
                          </div>
                        </div>
                        <div className="d-flex align-items-center">
                          <div className="d-flex chapter-list-play-icon">
                            <i className="fa-light fa-play" />
                          </div>
                          <div className="chapter-list-dec">
                            <p className="mb-0 single-chapter-list-content-text">Introduction to the User</p>
                            <div className="d-flex gap-3 items-center single-chapter-list-content-link">
                              <a href="#" className="underline">Preview</a>
                              <a href="#" className="underline">03:56</a>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <a className="single-chapter-list-menu text-dark" data-bs-toggle="collapse" href="#chapter-2" role="button" aria-expanded="false" aria-controls="collapseExample">
                      <div className="single-chapter-list ">
                        <div className="single-chapter-list-padding">
                          <div className="d-flex justify-content-between align-items-center">
                            <div className=" flex-shrink-0 chapter-list-count">
                              <span className="text-sm z-10 font-medium">01</span>
                            </div>
                            <div className="flex-grow-1 ps-3 pe-2 chapter-list-name">
                              <span className="chapter-list-name-title ">Setting up our Symfony App</span>
                            </div>
                            <div className="chapter-list-time">4:34</div>
                          </div>
                        </div>
                      </div>
                    </a>
                    <div id="chapter-2" className="collapse single-chapter-list-content">
                      <div className="all-sub-chapter">
                        <div className="d-flex align-items-center">
                          <div className="d-flex chapter-list-play-icon">
                            <i className="fa-light fa-play" />
                          </div>
                          <div className="chapter-list-dec">
                            <p className="mb-0 single-chapter-list-content-text">Introduction to the User</p>
                            <div className="d-flex gap-3 items-center single-chapter-list-content-link">
                              <a href="#" className="underline">Preview</a>
                              <a href="#" className="underline">03:56</a>
                            </div>
                          </div>
                        </div>
                        <div className="d-flex align-items-center">
                          <div className="d-flex chapter-list-play-icon">
                            <i className="fa-light fa-play" />
                          </div>
                          <div className="chapter-list-dec">
                            <p className="mb-0 single-chapter-list-content-text">Introduction to the User</p>
                            <div className="d-flex gap-3 items-center single-chapter-list-content-link">
                              <a href="#" className="underline">Preview</a>
                              <a href="#" className="underline">03:56</a>
                            </div>
                          </div>
                        </div>
                        <div className="d-flex align-items-center">
                          <div className="d-flex chapter-list-play-icon">
                            <i className="fa-light fa-play" />
                          </div>
                          <div className="chapter-list-dec">
                            <p className="mb-0 single-chapter-list-content-text">Introduction to the User</p>
                            <div className="d-flex gap-3 items-center single-chapter-list-content-link">
                              <a href="#" className="underline">Preview</a>
                              <a href="#" className="underline">03:56</a>
                            </div>
                          </div>
                        </div>
                        <div className="d-flex align-items-center">
                          <div className="d-flex chapter-list-play-icon">
                            <i className="fa-light fa-play" />
                          </div>
                          <div className="chapter-list-dec">
                            <p className="mb-0 single-chapter-list-content-text">Introduction to the User</p>
                            <div className="d-flex gap-3 items-center single-chapter-list-content-link">
                              <a href="#" className="underline">Preview</a>
                              <a href="#" className="underline">03:56</a>
                            </div>
                          </div>
                        </div>
                        <div className="d-flex align-items-center">
                          <div className="d-flex chapter-list-play-icon">
                            <i className="fa-light fa-play" />
                          </div>
                          <div className="chapter-list-dec">
                            <p className="mb-0 single-chapter-list-content-text">Introduction to the User</p>
                            <div className="d-flex gap-3 items-center single-chapter-list-content-link">
                              <a href="#" className="underline">Preview</a>
                              <a href="#" className="underline">03:56</a>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <a className="single-chapter-list-menu text-dark" data-bs-toggle="collapse" href="#chapter-3" role="button" aria-expanded="false" aria-controls="collapseExample">
                      <div className="single-chapter-list ">
                        <div className="single-chapter-list-padding">
                          <div className="d-flex justify-content-between align-items-center">
                            <div className=" flex-shrink-0 chapter-list-count">
                              <span className="text-sm z-10 font-medium">01</span>
                            </div>
                            <div className="flex-grow-1 ps-3 pe-2 chapter-list-name">
                              <span className="chapter-list-name-title ">Setting up our Symfony App</span>
                            </div>
                            <div className="chapter-list-time">4:34</div>
                          </div>
                        </div>
                      </div>
                    </a>
                    <div id="chapter-3" className="collapse single-chapter-list-content">
                      <div className="all-sub-chapter">
                        <div className="d-flex align-items-center">
                          <div className="d-flex chapter-list-play-icon">
                            <i className="fa-light fa-play" />
                          </div>
                          <div className="chapter-list-dec">
                            <p className="mb-0 single-chapter-list-content-text">Introduction to the User</p>
                            <div className="d-flex gap-3 items-center single-chapter-list-content-link">
                              <a href="#" className="underline">Preview</a>
                              <a href="#" className="underline">03:56</a>
                            </div>
                          </div>
                        </div>
                        <div className="d-flex align-items-center">
                          <div className="d-flex chapter-list-play-icon">
                            <i className="fa-light fa-play" />
                          </div>
                          <div className="chapter-list-dec">
                            <p className="mb-0 single-chapter-list-content-text">Introduction to the User</p>
                            <div className="d-flex gap-3 items-center single-chapter-list-content-link">
                              <a href="#" className="underline">Preview</a>
                              <a href="#" className="underline">03:56</a>
                            </div>
                          </div>
                        </div>
                        <div className="d-flex align-items-center">
                          <div className="d-flex chapter-list-play-icon">
                            <i className="fa-light fa-play" />
                          </div>
                          <div className="chapter-list-dec">
                            <p className="mb-0 single-chapter-list-content-text">Introduction to the User</p>
                            <div className="d-flex gap-3 items-center single-chapter-list-content-link">
                              <a href="#" className="underline">Preview</a>
                              <a href="#" className="underline">03:56</a>
                            </div>
                          </div>
                        </div>
                        <div className="d-flex align-items-center">
                          <div className="d-flex chapter-list-play-icon">
                            <i className="fa-light fa-play" />
                          </div>
                          <div className="chapter-list-dec">
                            <p className="mb-0 single-chapter-list-content-text">Introduction to the User</p>
                            <div className="d-flex gap-3 items-center single-chapter-list-content-link">
                              <a href="#" className="underline">Preview</a>
                              <a href="#" className="underline">03:56</a>
                            </div>
                          </div>
                        </div>
                        <div className="d-flex align-items-center">
                          <div className="d-flex chapter-list-play-icon">
                            <i className="fa-light fa-play" />
                          </div>
                          <div className="chapter-list-dec">
                            <p className="mb-0 single-chapter-list-content-text">Introduction to the User</p>
                            <div className="d-flex gap-3 items-center single-chapter-list-content-link">
                              <a href="#" className="underline">Preview</a>
                              <a href="#" className="underline">03:56</a>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <a className="single-chapter-list-menu text-dark" data-bs-toggle="collapse" href="#chapter-4" role="button" aria-expanded="false" aria-controls="collapseExample">
                      <div className="single-chapter-list ">
                        <div className="single-chapter-list-padding">
                          <div className="d-flex justify-content-between align-items-center">
                            <div className=" flex-shrink-0 chapter-list-count">
                              <span className="text-sm z-10 font-medium">01</span>
                            </div>
                            <div className="flex-grow-1 ps-3 pe-2 chapter-list-name">
                              <span className="chapter-list-name-title ">Setting up our Symfony App</span>
                            </div>
                            <div className="chapter-list-time">4:34</div>
                          </div>
                        </div>
                      </div>
                    </a>
                    <div id="chapter-4" className="collapse single-chapter-list-content">
                      <div className="all-sub-chapter">
                        <div className="d-flex align-items-center">
                          <div className="d-flex chapter-list-play-icon">
                            <i className="fa-light fa-play" />
                          </div>
                          <div className="chapter-list-dec">
                            <p className="mb-0 single-chapter-list-content-text">Introduction to the User</p>
                            <div className="d-flex gap-3 items-center single-chapter-list-content-link">
                              <a href="#" className="underline">Preview</a>
                              <a href="#" className="underline">03:56</a>
                            </div>
                          </div>
                        </div>
                        <div className="d-flex align-items-center">
                          <div className="d-flex chapter-list-play-icon">
                            <i className="fa-light fa-play" />
                          </div>
                          <div className="chapter-list-dec">
                            <p className="mb-0 single-chapter-list-content-text">Introduction to the User</p>
                            <div className="d-flex gap-3 items-center single-chapter-list-content-link">
                              <a href="#" className="underline">Preview</a>
                              <a href="#" className="underline">03:56</a>
                            </div>
                          </div>
                        </div>
                        <div className="d-flex align-items-center">
                          <div className="d-flex chapter-list-play-icon">
                            <i className="fa-light fa-play" />
                          </div>
                          <div className="chapter-list-dec">
                            <p className="mb-0 single-chapter-list-content-text">Introduction to the User</p>
                            <div className="d-flex gap-3 items-center single-chapter-list-content-link">
                              <a href="#" className="underline">Preview</a>
                              <a href="#" className="underline">03:56</a>
                            </div>
                          </div>
                        </div>
                        <div className="d-flex align-items-center">
                          <div className="d-flex chapter-list-play-icon">
                            <i className="fa-light fa-play" />
                          </div>
                          <div className="chapter-list-dec">
                            <p className="mb-0 single-chapter-list-content-text">Introduction to the User</p>
                            <div className="d-flex gap-3 items-center single-chapter-list-content-link">
                              <a href="#" className="underline">Preview</a>
                              <a href="#" className="underline">03:56</a>
                            </div>
                          </div>
                        </div>
                        <div className="d-flex align-items-center">
                          <div className="d-flex chapter-list-play-icon">
                            <i className="fa-light fa-play" />
                          </div>
                          <div className="chapter-list-dec">
                            <p className="mb-0 single-chapter-list-content-text">Introduction to the User</p>
                            <div className="d-flex gap-3 items-center single-chapter-list-content-link">
                              <a href="#" className="underline">Preview</a>
                              <a href="#" className="underline">03:56</a>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <a className="single-chapter-list-menu text-dark" data-bs-toggle="collapse" href="#chapter-5" role="button" aria-expanded="false" aria-controls="collapseExample">
                      <div className="single-chapter-list ">
                        <div className="single-chapter-list-padding">
                          <div className="d-flex justify-content-between align-items-center">
                            <div className=" flex-shrink-0 chapter-list-count">
                              <span className="text-sm z-10 font-medium">01</span>
                            </div>
                            <div className="flex-grow-1 ps-3 pe-2 chapter-list-name">
                              <span className="chapter-list-name-title ">Setting up our Symfony App</span>
                            </div>
                            <div className="chapter-list-time">4:34</div>
                          </div>
                        </div>
                      </div>
                    </a>
                    <div id="chapter-5" className="collapse single-chapter-list-content">
                      <div className="all-sub-chapter">
                        <div className="d-flex align-items-center">
                          <div className="d-flex chapter-list-play-icon">
                            <i className="fa-light fa-play" />
                          </div>
                          <div className="chapter-list-dec">
                            <p className="mb-0 single-chapter-list-content-text">Introduction to the User</p>
                            <div className="d-flex gap-3 items-center single-chapter-list-content-link">
                              <a href="#" className="underline">Preview</a>
                              <a href="#" className="underline">03:56</a>
                            </div>
                          </div>
                        </div>
                        <div className="d-flex align-items-center">
                          <div className="d-flex chapter-list-play-icon">
                            <i className="fa-light fa-play" />
                          </div>
                          <div className="chapter-list-dec">
                            <p className="mb-0 single-chapter-list-content-text">Introduction to the User</p>
                            <div className="d-flex gap-3 items-center single-chapter-list-content-link">
                              <a href="#" className="underline">Preview</a>
                              <a href="#" className="underline">03:56</a>
                            </div>
                          </div>
                        </div>
                        <div className="d-flex align-items-center">
                          <div className="d-flex chapter-list-play-icon">
                            <i className="fa-light fa-play" />
                          </div>
                          <div className="chapter-list-dec">
                            <p className="mb-0 single-chapter-list-content-text">Introduction to the User</p>
                            <div className="d-flex gap-3 items-center single-chapter-list-content-link">
                              <a href="#" className="underline">Preview</a>
                              <a href="#" className="underline">03:56</a>
                            </div>
                          </div>
                        </div>
                        <div className="d-flex align-items-center">
                          <div className="d-flex chapter-list-play-icon">
                            <i className="fa-light fa-play" />
                          </div>
                          <div className="chapter-list-dec">
                            <p className="mb-0 single-chapter-list-content-text">Introduction to the User</p>
                            <div className="d-flex gap-3 items-center single-chapter-list-content-link">
                              <a href="#" className="underline">Preview</a>
                              <a href="#" className="underline">03:56</a>
                            </div>
                          </div>
                        </div>
                        <div className="d-flex align-items-center">
                          <div className="d-flex chapter-list-play-icon">
                            <i className="fa-light fa-play" />
                          </div>
                          <div className="chapter-list-dec">
                            <p className="mb-0 single-chapter-list-content-text">Introduction to the User</p>
                            <div className="d-flex gap-3 items-center single-chapter-list-content-link">
                              <a href="#" className="underline">Preview</a>
                              <a href="#" className="underline">03:56</a>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <a className="single-chapter-list-menu text-dark" data-bs-toggle="collapse" href="#chapter-6" role="button" aria-expanded="false" aria-controls="collapseExample">
                      <div className="single-chapter-list">
                        <div className="single-chapter-list-padding">
                          <div className="d-flex justify-content-between align-items-center">
                            <div className=" flex-shrink-0 chapter-list-count">
                              <span className="text-sm z-10 font-medium">01</span>
                            </div>
                            <div className="flex-grow-1 ps-3 pe-2 chapter-list-name">
                              <span className="chapter-list-name-title ">Setting up our Symfony App</span>
                            </div>
                            <div className="chapter-list-time">4:34</div>
                          </div>
                        </div>
                      </div>
                    </a>
                    <div id="chapter-6" className="collapse single-chapter-list-content">
                      <div className="all-sub-chapter">
                        <div className="d-flex align-items-center">
                          <div className="d-flex chapter-list-play-icon">
                            <i className="fa-light fa-play" />
                          </div>
                          <div className="chapter-list-dec">
                            <p className="mb-0 single-chapter-list-content-text">Introduction to the User</p>
                            <div className="d-flex gap-3 items-center single-chapter-list-content-link">
                              <a href="#" className="underline">Preview</a>
                              <a href="#" className="underline">03:56</a>
                            </div>
                          </div>
                        </div>
                        <div className="d-flex align-items-center">
                          <div className="d-flex chapter-list-play-icon">
                            <i className="fa-light fa-play" />
                          </div>
                          <div className="chapter-list-dec">
                            <p className="mb-0 single-chapter-list-content-text">Introduction to the User</p>
                            <div className="d-flex gap-3 items-center single-chapter-list-content-link">
                              <a href="#" className="underline">Preview</a>
                              <a href="#" className="underline">03:56</a>
                            </div>
                          </div>
                        </div>
                        <div className="d-flex align-items-center">
                          <div className="d-flex chapter-list-play-icon">
                            <i className="fa-light fa-play" />
                          </div>
                          <div className="chapter-list-dec">
                            <p className="mb-0 single-chapter-list-content-text">Introduction to the User</p>
                            <div className="d-flex gap-3 items-center single-chapter-list-content-link">
                              <a href="#" className="underline">Preview</a>
                              <a href="#" className="underline">03:56</a>
                            </div>
                          </div>
                        </div>
                        <div className="d-flex align-items-center">
                          <div className="d-flex chapter-list-play-icon">
                            <i className="fa-light fa-play" />
                          </div>
                          <div className="chapter-list-dec">
                            <p className="mb-0 single-chapter-list-content-text">Introduction to the User</p>
                            <div className="d-flex gap-3 items-center single-chapter-list-content-link">
                              <a href="#" className="underline">Preview</a>
                              <a href="#" className="underline">03:56</a>
                            </div>
                          </div>
                        </div>
                        <div className="d-flex align-items-center">
                          <div className="d-flex chapter-list-play-icon">
                            <i className="fa-light fa-play" />
                          </div>
                          <div className="chapter-list-dec">
                            <p className="mb-0 single-chapter-list-content-text">Introduction to the User</p>
                            <div className="d-flex gap-3 items-center single-chapter-list-content-link">
                              <a href="#" className="underline">Preview</a>
                              <a href="#" className="underline">03:56</a>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}