import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import API from "../Network/API";

export default function ForgotPassword(){

    const [email, setEmail] = useState("");

    const [isLoading, setLoading] = useState(false);
    const [message, setMessage] = useState('');
    const [error, setError] = useState('');
    const dispatch = useDispatch();
    const navigate=useNavigate();

    const handleSubmit = (event) => {
        
        event.preventDefault();
        setMessage('');
        setError('');

        var data = {
            'email': email,
          }
          setLoading(true);
        API.post('/v1/oauth/forgot-password', data).then(response => { 
            setMessage(response.data.message);
            setLoading(false);
        }).catch((err) => { 
            setLoading(false);
            if (err.response && err.response.data && err.response.data.message) {
                setError(err.response.data.message);
            } else {
                setError('An error occurred. Please try again.');
            }
         });
    }

    return (
        <>
         <div className="sign-in-section">
        <div className="container">
          <div className="main-max-width d-flex justify-content-center mxw-400px">
            <div className="sign-content">
              <div className="box-content mb-4">
                <h4>Forgot Your Password?</h4>
                <div className="singup-link ">
                  <span className>
                    <Link to={`/signup-student`} className="text-decoration-underline text-dark me-1">Sign up as a student</Link>
                  </span>
                  <span className="text-dark">or</span>
                  <span>
                    <Link to={`/signup-tutor`} className="text-decoration-underline text-dark ms-1">Sign up as a tutor</Link>
                  </span>
                </div>
              </div>
              <div className="acount-s mb-20 d-none">
                <a href="#" className="btn rounded-pill w-100 mb-2 input-hight-44">
                  <img src="assets/img/google.svg" alt="image" /> Sign in with Google
                </a>
                <a href="#" className="btn rounded-pill w-100 mb-2 input-hight-44">
                  <img src="assets/img/facebook-3-logo-svgrepo-com.svg" alt="image" /> Sign in with Facebook
                </a>
                <a href="#" className="btn rounded-pill w-100 mb-2 input-hight-44">
                  <img src="assets/img/apple-logo-svgrepo-com.svg" alt="image" /> Continue with Apple
                </a>
              </div>
              <div className="loginDivider d-none">
                <p className="loginDivider-text mb-0">or</p>
              </div>
              <div className="log-from mb-30">
                <form onSubmit={handleSubmit}>
                  <div className="form-group mb-2">
                    <label htmlFor="exampleInputEmail1" className="form-label ">Email</label>
                    <input defaultValue={email} required onChange={(e) => setEmail(e.target.value)} type="email" className="form-control rounded-pill input-hight-44" id="exampleInputEmail1" placeholder="Your Email" />
                  </div>
             
                
                  <button type="submit" className="input-hight-44 btn btn-login w-100 box-shadow-1 rounded-pill fw-bold">Send Reset Link {isLoading ? (
                    <span
                      className="spinner-border spinner-border-sm ml-5"
                      role="status"
                      aria-hidden="true"
                    ></span>
                  ) : (
                    <span></span>
                  )}</button>
                </form>

                {message && <p className="success-message">{message}</p>}
                {error && <p className="error-message">{error}</p>}

              </div>
              <div className="sign-link text-center">
                <span>By clicking Log in or Continue with, you agree to Xller <Link to={`/terms`} className="main-colo">Terms of Use</Link> and <Link to={`/privacy-policy`} className="main-colo">Privacy Policy</Link></span>
              </div>
            </div>
          </div>
        </div>
      </div>
        </>
    )
}