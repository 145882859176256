export default function Terms() {
    return (
        <>
              <div className=" bg-light">
              <div className="container">
                    <p className=" pt-5"><h4>Terms &amp; Conditions</h4></p>
                    <p><strong>Effective Date:</strong> 11/01/2024</p>
                    <p>Welcome to <strong>Xller</strong>, an online education platform designed to help you learn and grow. By accessing or using our platform, you agree to these Terms &amp; Conditions. Please read them carefully.</p>
                    <h3>1. Acceptance of Terms</h3>
                    <p>By registering on Xller, accessing content, or using our services, you agree to abide by these Terms &amp; Conditions and any other applicable guidelines or policies on our platform.</p>
                    <h3>2. Account Registration and Responsibilities</h3>
                    <ul>
                        <li><strong>Eligibility</strong>: You must be at least 18 years old, or the age of legal consent in your jurisdiction, to use Xller. By creating an account, you confirm that you meet this requirement.</li>
                        <li><strong>Account Security</strong>: You are responsible for maintaining the confidentiality of your login credentials and for any activity that occurs under your account.</li>
                        <li><strong>Account Information</strong>: You agree to provide accurate and complete information during registration and to keep it updated as needed.</li>
                    </ul>
                    <h3>3. Use of the Platform</h3>
                    <ul>
                        <li><strong>Permitted Use</strong>: Xller grants you a limited, non-transferable license to access our platform for personal, educational purposes only. You agree not to use the platform for any commercial purposes without our explicit permission.</li>
                        <li><strong>Prohibited Conduct</strong>: You agree not to misuse the platform, including but not limited to engaging in unlawful, harmful, or disruptive activities, such as:<ul>
                            <li>Interfering with the security or proper functioning of the platform.</li>
                            <li>Using the platform to transmit any viruses, spam, or unsolicited advertisements.</li>
                            <li>Engaging in any activity that could harm Xller’s reputation or other users.</li>
                        </ul>
                        </li>
                    </ul>
                    <h3>4. Content and Intellectual Property</h3>
                    <ul>
                        <li><strong>Ownership</strong>: All content on Xller, including courses, videos, images, logos, and trademarks, is owned or licensed by Xller and is protected by copyright, trademark, and other intellectual property laws.</li>
                        <li><strong>User License</strong>: You may not modify, distribute, sell, or lease any part of the content without our permission. Unauthorized use of Xller’s content may result in termination of your access to the platform and/or legal action.</li>
                        <li><strong>User Content</strong>: If you post or upload content to Xller, you grant us a non-exclusive, worldwide license to use, display, and distribute your content for purposes related to the operation and promotion of the platform.</li>
                    </ul>
                    <h3>5. Payments, Refunds, and Cancellations</h3>
                    <ul>
                        <li><strong>Payments</strong>: Certain courses or services on Xller may require payment. You agree to provide accurate payment information and authorize Xller or its payment processors to charge the associated fees.</li>
                        <li><strong>Refund Policy</strong>: Refunds may be available based on Xller’s refund policy. Please review our refund policy carefully before making a purchase.</li>
                        <li><strong>Cancellation</strong>: You may cancel your account at any time. However, cancellation does not entitle you to a refund unless specified in our refund policy.</li>
                    </ul>
                    <h3>6. Termination of Use</h3>
                    <p>We reserve the right to suspend or terminate your access to Xller at our sole discretion, for any reason, including but not limited to breaches of these Terms &amp; Conditions, illegal activities, or abusive behavior. Upon termination, your right to use the platform will immediately cease.</p>
                    <h3>7. Disclaimers and Limitation of Liability</h3>
                    <ul>
                        <li><strong>Disclaimer</strong>: Xller and its content are provided "as is" without any warranties, express or implied, including but not limited to warranties of fitness for a particular purpose, merchantability, and non-infringement.</li>
                        <li><strong>Limitation of Liability</strong>: To the extent permitted by law, Xller shall not be liable for any indirect, incidental, or consequential damages arising from your use or inability to use the platform, even if advised of the possibility of such damages.</li>
                    </ul>
                    <h3>8. Changes to Terms &amp; Conditions</h3>
                    <p>Xller reserves the right to modify these Terms &amp; Conditions at any time. Changes will be effective immediately upon posting on our platform. By continuing to use Xller after any changes, you agree to the updated terms.</p>
                    <h3>9. Governing Law</h3>
                    <p>These Terms &amp; Conditions shall be governed by and construed in accordance with the laws of [Your Country/State], without regard to its conflict of laws principles. Any legal action or proceeding arising out of or related to these terms shall be brought exclusively in the courts located in [Your Country/State].</p>
                    <h3>10. Contact Us</h3>
                    <p>If you have any questions about these Terms &amp; Conditions, please contact us at:</p>
                    <p><strong>Xller</strong><br />Email: <strong>info@xller.com</strong></p>
                </div>
            </div>
        </>
    )
}