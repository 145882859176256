export default function PrivacyPolicy() {
    return (
        <>
        <div className=" bg-light">
        <div className="container">
            <p className=" pt-5"><h4>Privacy Policy</h4></p>
            <p><strong>Effective Date:</strong> 11/01/2024</p>
            <p>At <strong>Xller</strong>, we prioritize the privacy and security of your personal information. This Privacy Policy explains how we collect, use, share, and protect information related to our services.</p>
            <h3>1. Information We Collect</h3>
            <p>We collect the following types of information:</p>
            <ul>
                <li><strong>Personal Information</strong>: When you register on Xller, we may collect information such as your name, email address, phone number, and other identifiers necessary for creating and managing your account.</li>
                <li><strong>Educational Information</strong>: This includes course enrollments, performance data, assessments, and interactions with content and instructors.</li>
                <li><strong>Payment Information</strong>: For paid services, we collect payment details, which are processed through a secure third-party payment provider.</li>
                <li><strong>Usage Data</strong>: We may collect information on how you interact with our platform, including IP addresses, device types, browser settings, and pages viewed to help us improve our services and provide a personalized experience.</li>
            </ul>
            <h3>2. How We Use Your Information</h3>
            <p>Xller uses collected information to:</p>
            <ul>
                <li>Provide and maintain the educational services you request.</li>
                <li>Personalize your learning experience and recommend courses or materials based on your interests.</li>
                <li>Process transactions, including payments and refunds.</li>
                <li>Communicate with you regarding your account, courses, updates, and promotions.</li>
                <li>Improve our platform through analytics, research, and development.</li>
            </ul>
            <h3>3. Sharing of Information</h3>
            <p>We may share your information under the following circumstances:</p>
            <ul>
                <li><strong>Service Providers</strong>: We share information with third-party providers to perform services on our behalf, such as payment processing, analytics, and hosting.</li>
                <li><strong>Legal Requirements</strong>: We may disclose your information if required by law or to protect our rights, users, or the public.</li>
                <li><strong>Business Transfers</strong>: If Xller undergoes a merger, acquisition, or asset sale, your information may be transferred as part of the transaction.</li>
            </ul>
            <h3>4. Security</h3>
            <p>We use industry-standard security measures to protect your information. However, no online platform can guarantee complete security, and you are responsible for safeguarding your account credentials.</p>
            <h3>5. Your Choices and Rights</h3>
            <p>You have the right to:</p>
            <ul>
                <li>Access, correct, or delete your personal information.</li>
                <li>Manage your preferences for receiving promotional communications.</li>
                <li>Opt-out of certain data collection practices, where applicable.</li>
            </ul>
            <p>Please contact us at <a href="info@xller.com"><strong>info@xller.com</strong></a> to make a request regarding your personal data.</p>
            <h3>6. Cookies and Tracking Technologies</h3>
            <p>We use cookies and similar tracking technologies to provide and enhance your experience on Xller. You may adjust your browser settings to refuse cookies; however, some features of our platform may not function properly without them.</p>
            <h3>7. Changes to This Privacy Policy</h3>
            <p>We may update our Privacy Policy to reflect changes in our practices or for legal reasons. We encourage you to review this page periodically for the latest information on our privacy practices.</p>
            <h3>8. Contact Us</h3>
            <p>For questions or concerns about this Privacy Policy, please contact us at:</p>
            <p><strong>Xller</strong> Email: <strong>info@xller.com</strong></p>
            </div>
            </div>
        </>
    )
}